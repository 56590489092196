<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <modalDetail 
            :modal="showModalDetail" 
            :detailItems="detailItems"
            :detailJson="detailJson"
            @closeModal="close" 
        /> 
        <CRow>
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mb-3 mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.lapseSummary') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XLSX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow class="m-0">
            <CCol sm="12" lg="3" class="center-field">
                <div class="form-group form-row">
                    <label class="col-form-label col-sm-12 col-lg-3 text-right pt-2">{{$t('label.date')}}</label>
                    <CCol sm="12" lg="9" class="center-field">
                        <vue-datepicker 
                            type="date"
                            header
                            :lang="this.$i18n.locale"
                            :editable="false"
                            :clearable="false"
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            time-title-format="DD/MM/YYYY"
                            v-model="DateReport"
                            :disabled-date="validateDateRange"
                            :append-to-body="false"
                            value-type="format"
                            :show-second="false"
                            @change="filterDateRange"
                        >
                            <template #icon-calendar>
                                <div style="display: none"></div>
                            </template>
                        </vue-datepicker> 
                    </CCol>
                </div>
            </CCol>
            <CCol sm="12" lg="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
                    :label="$t('label.lapse')"
                    :options="LapseFormatted"
                    v-model.trim="LapseId"
                    :value.sync="LapseId"
                />        
            </CCol>
            <CCol class="col-sm-12 col-lg-auto center-field mr-0" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search'), placement: 'top-end'}" 
                    @click="getLapseDetailList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
            </CCol>
        </CRow>
        <CRow class="m-0">    
            <CCol sm="12" lg="3" xl="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')"
                    v-model="textSearch"
                    required 
                >
                </CInput>
            </CCol>
            <CCol sm="5" lg="5" xl="5" class="center-field"></CCol>
            <CCol sm="12" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    v-model="SizeChanged"
                    :value.sync="SizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                >
                </CSelect>
            </CCol>  
        </CRow>
        <CRow class="m-0">          
            <CCol sm="12" lg="12" xl="12" class="  mb-2">
                <div class="flex-grow-1 bd-highlight">
                    <ag-grid-vue
                        v-if="showGrid"
                        style="width: 100%; height: 100vh;"
                        class="ag-theme-alpine"
                        :gridOptions="gridOptions"
                        :defaultColDef="defaultColDef"
                        :localeText="localeText"
                        :columnDefs="columnDefs"
                        :rowData="formatedItems"
                        :suppressRowClickSelection="true"
                        :groupSelectsChildren="true"
                        :enableRangeSelection="true"
                        :pagination="true"
                        :paginationPageSize="paginationPageSize"
                        :paginationNumberFormatter="paginationNumberFormatter"
                        rowSelection="single"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import modalDetail from './modal-detail';
    //data
    function data() {
        return {
            SizeChanged:50,
            textSearch:'',
            gridOptions: null,
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            VisitId: '',
            rows: [],
            loadingOverlay: false,
            previousDate: new Date(),
            laterDate: new Date(),
            nulo: '', 
            LapseId: '',
            DateReport: '',
            craneOptions: [],
            holdOptions: [],
            LapseOptions: [],
            CraneAlias: '', 
            showModalDetail: false,
            detailItems: {},
            detailJson: [],
        }
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {
            onCellClicked: (event) => {
                if(event.colDef.colId == 'seeDetail'){
                    this.showModalDetail = true;
                    this.detailItems = event.data;
                    this.detailJson = this.detailItems.DetailJson;
                }
            },
            onCellMouseOver: (event) => {
                if(event.colDef.colId == 'seeDetail'){
                tippy('#mySeeDetailButton', {
                    content: this.$t('label.see')+' '+this.$t('label.details'),
                })
                }
            },
            };

        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    async function onBtnExport(valor) {
        this.LoadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(rowData.length !== 0) {
        let valores = [];
        let currentDate = new Date();
        valores[0] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[1] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

        await this.getOrdersExcel(rowData,this.$t('label.lapseSummary'),valor, valores, 1);
        }else{
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.noRecordsAvailbleReport'),
            type: "error"
        });
        }
        this.LoadingOverlay = false;
    }

    async function getLapseDetailList(){
        this.loadingOverlay = true;
      
        let ReportJson = {
            VisitId: this.Visit,
            VisitLapseId: this.LapseId,
            DateReport: this.DateReport ? DateFormater.formatDateTimeWithoutSlash(this.DateReport) : '',
        }

        this.$http.ejecutar('POST', 'VisitGeneralCargoLapseReportSummary', ReportJson, { root: 'ReportJson' })
        .then(response => {
            this.rows = [...response.data.data];
            this.nulo = String(response.data.data[0].Json);
            this.loadingOverlay = false;
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        });
    }

    async function filterDateRange(event) {
        this.DateReport=event;
        this.LapseId='';
        this.getLapseList(true);
    }

    async function getLapseList(loading) {
        this.loadingOverlay = true;
        let date = this.DateReport ? DateFormater.formatDateTimeWithoutSlash(this.DateReport) : '';
        await this.$http.get("VisitLapseReport-list", {VisitId: this.Visit, DateReport: date})
        .then(response => {
            this.LapseOptions = response.data.data;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        }).then(() => {
            loading ? this.loadingOverlay = false : '';
        });
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }

    async function refreshComponent() {
        this.loadingOverlay = true;
        this.textSearch="";
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.CraneAlias= '';
        this.LapseId = '';
        this.DateReport= '';
        this.craneName= '';
        this.vesselCraneId= '';
        this.showModalDetail= false;
        this.detailJson = [];
        this.detailItems = {};
        await this.getDateRange();
        await this.getLapseList();
        await this.getLapseDetailList();
    }

    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    function close(){
        this.showModalDetail = false;
        this.detailItems = {};
        this.detailJson = [];
    }

    //computed
    function columnDefs(){
        let columnDefs = [
            {
                headerName: "",
                field: 'seeDetail',
                width: 45,
                minWidth: 45,
                maxWidth: 45,
                suppressMovable: true,
                lockPosition: true,
                colId: "seeDetail",
                cellRenderer: params => {
                    if(params.value !== ""){
                        return  `
                            <div align="center">
                                <button id="mySeeDetailButton" class="btn btn-watch mr-1 btn-sm" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.08 20.51" role="img" class="c-icon">
                                        <path class="cls-1" d="M15,0A16.17,16.17,0,0,0,0,10.25a16.15,16.15,0,0,0,30.08,0A16.16,16.16,0,0,0,15,0Zm0,17.09a6.84,6.84,0,1,1,6.84-6.84A6.84,6.84,0,0,1,15,17.09ZM15,6.15a4.11,4.11,0,1,0,4.1,4.1A4.1,4.1,0,0,0,15,6.15Z"></path>
                                    </svg>
                                </button>
                            </div>
                        `
                    }
                },
                cellClass: 'center-cell-especial px-1',
            },
            {
                field: "Nro",
                headerName: "#",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                lockVisible: true,
                lockPosition: true,
                minWidth: 70,
            },
            {
                field: "VisitLapseCode",
                headerName: this.$t('label.lapse'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "VisitLapseDate",
                headerName: `${this.$t('label.date')} ${this.$t('label.lapse')}`,
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "VisitLapseDs",
                headerName: this.$t('label.hour'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
            },
            {
                field: "SubTotal",
                headerName: 'SUBTOTAL',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            },
            {
                field: "Mph",
                headerName: 'MPH',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 160,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            },
            {
                field: "TotalGeneral",
                headerName: this.$t('label.totalQuantity'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return variable;
                },
            },
            // {
            //     field: "DetailJson",
            //     headerName: this.$t('label.detail'),
            //     headerClass: 'center-cell-especial',
            //     cellClass: 'center-cell-especial',
            //     filter: "agTextColumnFilter",
            //     minWidth: 350,
            // },
        ]
        return columnDefs;
    }

    function formatedItems() {
        if(this.rows.length !== 0 && this.nulo!="null"){
            let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
            return this.rows.map((item) => {
                return {
                    ...item,
                    Nro: item.Nro ?? '',
                    VisitLapseCode: item.VisitLapseCode ?? 'N/A',
                    VisitLapseDate: item.VisitLapseDate ? DateFormater.formatDateTimeWithSlash(item.VisitLapseDate) : 'N/A',
                    VisitLapseDs: item.VisitLapseDs ?? 'N/A',
                    SubTotal: item.SubTotal ?? 0,
                    Mph: item.Mph ?? 0,
                    TotalGeneral: item.TotalGeneral ?? 0,
                    // DetailJson: item.DetailJson.length > 0 ? item.DetailJson.map(Packaging => `${Packaging.CraneAlias},  
                    // ${Packaging[`PackagingName${_lang}`].trim()}, ${Packaging.OperationAcronym}, 
                    // ${Packaging.Quantity ?? 0}`).join(', ')  : 'N/A',
                }  
            })
        }
    }
    function LapseFormatted(){
        if(this.LapseOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.LapseOptions.map(function(e){
                chart.push({
                    value: e.VisitLapseId, 
                    label: `${e.VisitLapseCode} (${e.HourRange}) `,
                })  
            })
            return chart;
        }
    }

    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }

    function SizeChanged(newQuestion) {
        this.gridApi.paginationSetPageSize(parseInt(newQuestion));
    }
    export default {
        name:'index-lapse-summary-general-cargo',
        props: {currentModuleId: Boolean, loading: Boolean},
        data,
        components:{ modalDetail },
        beforeMount,
        methods:{
            onBtnExport,
            onGridReady,
            getLapseDetailList,
            filterDateRange,
            validateDateRange,
            getLapseList,
            refreshComponent,
            getDateRange,
            close
        },
        mixins: [ReportesVisitas, AgGrid],
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                tabIndex: state => state.visitas.tabIndex,
                user: (state) => state.auth.user,
                itinerarySelected: state => state.visitas.itinerarySelected,
                dropItemAgenda: state => state.visitas.dropItemAgenda,
            }),
            LapseFormatted
        },
        directives: {
            uppercase: {
                bind(el, _, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
                });
                }
            },
        },
        watch:{
            textSearch,
            SizeChanged,    
            currentModuleId: async function (newValue) {
                if (newValue) {
                    this.SizeChanged = 50;
                    this.VisitId = this.Visit;
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        },
    }
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>